import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

// Define a type for the slice state
interface DrawerState {
  isOpen: boolean
  cardIsOpen: boolean
  content?: any
}

// Define the initial state using that type
const initialState: DrawerState = {
  isOpen: false,
  cardIsOpen: true,
  content: null,
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    drawerIsOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean
        cardIsOpen: boolean
      }>
    ) => {
      state.isOpen = action.payload.isOpen
      state.cardIsOpen = action.payload.cardIsOpen
    },
    drawerContent: (state, action: PayloadAction<any>) => {
      state.content = action.payload
    },
    openDrawer: (
      state,
      action: PayloadAction<{
        content: any
        isOpen: boolean
        cardIsOpen: boolean
      }>
    ) => {
      state.content = action.payload.content
      state.isOpen = action.payload.isOpen
      state.cardIsOpen = action.payload.cardIsOpen
    },
  },
})

export const { drawerIsOpen, drawerContent, openDrawer } = drawerSlice.actions
export const drawer = (state: RootState) => state.drawer

export default drawerSlice.reducer
