import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

// Define a type for the slice state
interface ToolbarState {
  isOpen: boolean
  content?: any
}

// Define the initial state using that type
const initialState: ToolbarState = {
  isOpen: false,
  content: null,
}

export const ToolbarSlice = createSlice({
  name: 'Toolbar',
  initialState,
  reducers: {
    ToolbarIsOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean
      }>
    ) => {
      state.isOpen = action.payload.isOpen
    },
    ToolbarContent: (state, action: PayloadAction<any>) => {
      state.content = action.payload
    },
    openToolbar: (
      state,
      action: PayloadAction<{
        content: any
        isOpen: boolean
      }>
    ) => {
      state.content = action.payload.content
      state.isOpen = action.payload.isOpen
    },
  },
})

export const { ToolbarIsOpen, ToolbarContent, openToolbar } =
  ToolbarSlice.actions
export const Toolbar = (state: RootState) => state.toolbar

export default ToolbarSlice.reducer
