import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

// Define a type for the slice state
interface MapState {
  isReady: boolean
}

// Define the initial state using that type
const initialState: MapState = {
  isReady: false,
}

export const mapSlice = createSlice({
  name: 'map/ready',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    ready: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload
    },
  },
})

export const { ready } = mapSlice.actions
export const mapIsReady = (state: RootState) => state.mapReady.isReady

export default mapSlice.reducer
