import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'
import './Brest44.css'

import { ChaptersEntity, Data } from './types/types'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import React, { useEffect, useRef, useState } from 'react'
import {
  getBoundsByPoints,
  getWindowSize,
  setLayerOpacity,
} from './tools/helpers'
import { useAppDispatch, useAppSelector } from './hooks'

import AnimationData from './animbarge.json'
import DebugInfos from './components/DebugInfos'
import Drawer from './components/Drawer/Drawer'
import { InView } from 'react-intersection-observer'
import Popup from 'reactjs-popup'
import ReactDOM from 'react-dom'
import StepEnd from './components/Step/StepEnd'
import StepFull from './components/Step/StepFull'
import StepFullWithPano from './components/Step/StepFullWithPano'
import StepLeft from './components/Step/StepLeft'
import StepLoading from './components/Step/StepLoading'
import Toolbar from './components/Toolbar'
import { ToolbarIsOpen } from './reducers/toolbarSlice'
import { currentChapter } from './reducers/dataSlice'
import { drawerIsOpen } from './reducers/drawerSlice'
import mapboxgl from 'mapbox-gl'
import useBreakpoint from './tools/useBreakpoint'
import { useMapbox } from './tools/useMapbox'

const accessToken =
  'pk.eyJ1IjoiY2xhZGppZGFuZSIsImEiOiJjbHBqZXI1eXUwODA4MmpzNnVtajhtMXN2In0.w6lfbvUbzmjHnd391EmVVA'

const initModal = { show: false, content: null }

const Brest44UseMap = React.memo((datas: Data) => {
  const drawer = useAppSelector((state) => state.drawer)
  const toolbar = useAppSelector((state) => state.toolbar)
  const dispatch = useAppDispatch()
  const [mapReady, setMapReady] = useState(false)
  const [current, setCurrent] = useState(null)
  const bp = useBreakpoint()
  const [windowSize] = useState(getWindowSize())

  const [showModal, setShowModal] = useState(initModal)

  const [showMap, setShowMap] = useState(true)
  const mapContainerRef = useRef(null)

  const map = useMapbox(mapContainerRef, accessToken, {
    style: "mapbox://styles/cladjidane/ckwkhr2cf0ktx14o2q15cm80q", // datas.style,
    center: datas.startmap.center,
    zoom: datas.startmap.zoom,
    bearing: datas.startmap.bearing,
    pitch: datas.startmap.pitch,
  })

  // Init de la map
  useEffect(() => {
    if (map) {
      map.scrollZoom.disable()

      map.on('load', () => {
        setMapReady(true)
        if (datas.custom_sources) {
          // Add sources
          datas.custom_sources.sources.map((source: { id: any }) =>
            map.addSource(source.id, {
              ...source,
            })
          )
          // Add layers
          datas.custom_sources.layers.map((layer: any) => map.addLayer(layer))
        }
        return
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  // Gestion des chapitres
  useEffect(() => {
    if (map && current !== null) {
      var chapter = datas.chapters.find(
        (chap: { id: string }) => chap.id === 'chapter-' + current
      )

      // Suppression des popups si existent.
      // C'est un peu bourin, je ne suis pas sûr que c'est la meilleur solution
      const popups = document.getElementsByClassName('mapboxgl-popup')
      if (popups.length) {
        for (const element of Array.from(popups)) {
          element.remove()
        }
      }

      // Gestion des layers pour le chapitre courant (onChapterEnter)
      if (chapter.onChapterEnter.length > 0) {
        chapter.onChapterEnter.forEach((layer: any) => {
          setLayerOpacity(layer, map)
        })
      }

      //if (chapter.styleCompare) setMapCompare(true)
      //else setMapCompare(false)

      if (chapter.hide_map) {
        setShowMap(false)
        return
      } else setShowMap(true)

      if (chapter.location.center === null) return

      if (chapter.location.style) map.setStyle(chapter.location.style)

      if (chapter.location.bound) {
        let padding = {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        }
        // Padding for mobile
        if (bp === 'xs' || bp === 'sm') {
          padding = {
            left: 30,
            right: 30,
            bottom: 30,
            top: windowSize.innerHeight / 2,
          }
        } else
          padding = {
            left: drawer.cardIsOpen ? windowSize.innerWidth / 3 + 50 : 50,
            right: drawer.isOpen ? windowSize.innerWidth / 3 + 50 : 50,
            top: 50,
            bottom: 50,
          }

        map.fitBounds(getBoundsByPoints(chapter.location.bound), {
          padding: padding,
          pitch: chapter.location.pitch !== 0 ? chapter.location.pitch : 0,
          linear: true,
          duration: chapter.location.duration || 3000,
          essential: true,
        })
      } else {
        //map['flyTo'](chapter.location)
      }

      if (chapter.popup) {
        chapter.popup.forEach((point) => {
          const popupNode = document.createElement('div')
          ReactDOM.render(
            <div>
              <h2>{point.title}</h2>
              {point.desc && <div>{point.desc}</div>}
              <img
                alt='Visuel à zoomer'
                onClick={() =>
                  setShowModal({ show: true, content: point.image })
                }
                src={point.image}
              />
            </div>,
            popupNode
          )
          new mapboxgl.Popup()
            .setLngLat(point.coordinates)
            .setDOMContent(popupNode)
            .addTo(map)

          return
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, map])

  useEffect(() => {
    if (map && drawer && current) {
      var chapter = datas.chapters.find(
        (chap: { id: string }) => chap.id === 'chapter-' + current
      )

      let padding = {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      }
      // Padding for mobile
      if (bp === 'xs' || bp === 'sm') {
        padding = {
          left: 30,
          right: 30,
          bottom: 30,
          top: drawer.cardIsOpen ? windowSize.innerHeight / 2 : 30,
        }
      } else
        padding = {
          left: drawer.cardIsOpen ? windowSize.innerWidth / 3 + 50 : 50,
          right: drawer.isOpen ? windowSize.innerWidth / 3 + 50 : 50,
          top: 50,
          bottom: 50,
        }
      map.fitBounds(getBoundsByPoints(chapter.location.bound), {
        padding: padding,
        linear: true,
        duration: chapter.location.duration || 1000,
        essential: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawer.cardIsOpen])

  const renderStep = (record: ChaptersEntity, i: number) => {
    switch (record.type) {
      case 'load':
        return <StepLoading record={record} index={i} />
      case 'full':
        return <StepFull record={record} index={i} />
      case 'left':
        return <StepLeft record={record} index={i} />
      case 'fullwithpano':
        return <StepFullWithPano record={record} index={i} />
      case 'end':
        return <StepEnd record={record} index={i} />

      default:
        return <StepFull record={record} index={i} />
    }
  }

  return (
    <>
      {!mapReady && (
        <div className='flex flex-col items-center justify-center h-screen w-full absolute z-50 bg-white'>
          <Player
            autoplay
            loop
            src={AnimationData}
            style={{ height: '500px', width: '500px' }}
          >
            <Controls visible={false} />
          </Player>
        </div>
      )}{' '}
      <div
        className='cc gg h-screen overflow-scroll snap-mandatory snap-y  scroll-smooth'
        id='container'
      >
        {!process.env.NODE_ENV ||
          (process.env.NODE_ENV === 'developmenttt' && (
            <DebugInfos infos={datas}></DebugInfos>
          ))}
        <div
          className={`${!showMap && 'opacity-0'
            } absolute inset-0 transition duration-500 h-screen`}
          ref={mapContainerRef}
        />

        <Toolbar map={map} />

        {datas &&
          datas.chapters.map((record: ChaptersEntity, i: number) => {
            return (
              <InView
                key={`i-${i}`}
                threshold={0.5}
                onChange={(inView, entry) => {
                  if (toolbar.isOpen)
                    dispatch(
                      ToolbarIsOpen({
                        isOpen: false,
                      })
                    )
                  if (!drawer.cardIsOpen)
                    dispatch(
                      drawerIsOpen({
                        isOpen: false,
                        cardIsOpen: true,
                      })
                    )
                  if (inView) {
                    dispatch(currentChapter(record))
                    setCurrent(i)
                  }
                }}
              >
                {({ ref, inView }) => (
                  <div
                    ref={ref}
                    className={`step container-step-${record.type} relative ${record.id
                      } ${record.id === 'chapter-' + current ? 'current' : ''
                      } items-center flex snap-start snap-always content-section h-full w-full pointer-events-none overflow-hidden`}
                    id={`${record.id}`}
                  >
                    {renderStep(record, i)}
                  </div>
                )}
              </InView>
            )
          })}
      </div>
      {drawer.isOpen && <Drawer isOpen={drawer.isOpen}></Drawer>}
      <Popup
        open={showModal.show}
        modal
        onClose={() => setShowModal(initModal)}
      >
        <img src={showModal.content} alt='Visuel zommé' />
        <div
          onClick={() => setShowModal(initModal)}
          className='sm:w-16 sm:h-16 inline-flex items-center justify-center rounded-full bg-white text-blue flex-shrink-0 pointer-events-auto cursor-pointer absolute top-10 right-10'
        >
          <svg
            className='sm:w-8 sm:h-8 text-blue'
            aria-hidden='true'
            focusable='false'
            data-prefix='far'
            data-icon='times-circle'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
          >
            <path
              fill='currentColor'
              d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z'
            ></path>
          </svg>
        </div>
      </Popup>
    </>
  )
})

export default Brest44UseMap
