import mapboxgl from 'mapbox-gl'

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

// TMP
export const humanLayer = {
  'arrow-endline-2': 'Flèches',
  'arrow-endline': 'Flèches',
  'progression-allies-brest-2': 'Ligne de progressions alliés',
  'progression-allies-brest-1': 'Ligne de progressions alliés',
  'allies-2': 'Ligne de progression alliés vers Brest',
  'allies-1': 'Ligne de progression alliés vers Brest',
  'ligne-allemande': 'Ligne Allemande',
  ports: 'Ports',
  plages: 'Plages du débarquements',
  'layer-plan': 'Plan de brest',
  'layer-brest46': 'Vue satellite de brest 1946',
  'mapbox-satellite': 'Vue satellite actuelle',
}

const layerTypes = {
  fill: ['fill-opacity'],
  line: ['line-opacity'],
  circle: ['circle-opacity', 'circle-stroke-opacity'],
  symbol: ['icon-opacity', 'text-opacity'],
  raster: ['raster-opacity'],
  'fill-extrusion': ['fill-extrusion-opacity'],
  heatmap: ['heatmap-opacity'],
}

export const getLayerPaintType = (layer, map) => {
  if (!layer) return layerTypes
  console.log(map.getStyle(), layer)
  if (!map.getLayer(layer)) return null
  var layerType = map.getLayer(layer).type

  return layerTypes[layerType]
}

export const setLayerOpacity = (layer, map) => {
  var paintProps = getLayerPaintType(layer.layer, map)
  if (!paintProps) return
  paintProps.forEach(function (prop) {
    var options = {}
    if (layer.duration) {
      var transitionProp = prop + '-transition'
      options = { duration: layer.duration }
      map.setPaintProperty(layer.layer, transitionProp, options)
    }
    map.setPaintProperty(layer.layer, prop, parseInt(layer.opacity), options)
  })
}

export const getBoundsByPoints = (bounds) => {
  let bounding = new mapboxgl.LngLatBounds(bounds[0][0], bounds[0][0])

  for (const coord of bounds[0]) {
    bounding.extend(coord)
  }

  return bounding
}
