import {
  AiFillCloseCircle,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai'
import React, { useEffect, useState } from 'react'

import OpenSeaDragon from 'openseadragon'

const OpenSeaDragonViewer = ({ img, handleClose }) => {
  const id = 'osd-' + Math.floor(Math.random() * 100)
  const [viewer, setViewer] = useState(null)
  const [image] = useState({
    type: 'legacy-image-pyramid',
    levels: img ? [...img] : [],
  })
  const divRef = React.useRef(null)

  useEffect(() => {
    const osd = OpenSeaDragon({
      id: id,
      prefixUrl: 'openseadragon-images/',
      animationTime: 0.5,
      tileSources: image,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 2,
      minZoomLevel: 1,
      visibilityRatio: 1,
      defaultZoomLevel: 0,
      showNavigationControl: false,
    })
    osd.innerTracker.scrollHandler = false
    setViewer(osd)

    /*
    const areAllFullyLoaded = () => {
      var tiledImage
      var count = viewer.world.getItemCount()
      for (var i = 0; i < count; i++) {
        tiledImage = viewer.world.getItemAt(i)
        if (!tiledImage.getFullyLoaded()) {
          return false
        }
      }
      return true
    }

    var isFullyLoaded = false

    osd.world.addHandler('add-item', function (event) {
      var tiledImage = event.item
      tiledImage.addHandler('fully-loaded-change', function () {
        var newFullyLoaded = areAllFullyLoaded()
        if (newFullyLoaded !== isFullyLoaded) {
          isFullyLoaded = newFullyLoaded
          console.log(isFullyLoaded)
        }
      })
    })
    */

    return () => {
      viewer && viewer.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='absolute xs:right-2 xs:top-6 lg:right-12 lg:top-12 flex z-10'>
        <div
          onClick={() => viewer.viewport.zoomBy(1.5)}
          className='cursor-pointer m-1 rounded-full flex justify-center items-center xs:w-8 xs:h-8 lg:w-10 lg:h-10 bg-red'
        >
          <AiOutlineZoomIn size='1.5em' color='white' />
        </div>
        <div
          onClick={() => viewer.viewport.zoomBy(0.5)}
          className='cursor-pointer m-1 rounded-full flex justify-center items-center xs:w-8 xs:h-8 lg:w-10 lg:h-10 bg-red'
        >
          <AiOutlineZoomOut size='1.5em' color='white' />
        </div>
        <div
          onClick={() => handleClose()}
          className='cursor-pointer m-1 rounded-full flex justify-center items-center xs:w-8 xs:h-8 lg:w-10 lg:h-10 bg-red'
        >
          <AiFillCloseCircle size='1.5em' color='white' />
        </div>
        {/*

          // Pour la gestion des fullscreen avec OpenSeaDragon, mais ça marche pas :(
          // Je laisse de côté pour l'instant - voir l'autre partie dans StmBrest44.js ligne 133

        <div
          onClick={() => viewer.setFullScreen(true)}
          className='cursor-pointer m-1 rounded-full flex justify-center items-center xs:w-8 xs:h-8 lg:w-10 lg:h-10 bg-red'
        >
          <AiOutlineFullscreen size='1.5em' color='white' />
        </div>
        */}
      </div>
      <div
        id={id}
        style={{
          height: '100%',
          width: '100%',
        }}
        ref={divRef}
      ></div>
    </>
  )
}

export { OpenSeaDragonViewer }
