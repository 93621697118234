import { ChaptersEntity } from '../../types/types'
import { FC } from 'react'

type MenuProps = {
  items: ChaptersEntity[]
}

const Menu: FC<MenuProps> = ({ items, ...props }) => {
  return (
    <div className="">
      <ul className="mt-5 space-y-2">
        {items.map((item) => (
          <li key={item.id} className="flex">
            <a
              href={`#${item.id}`}
              className="flex items-center rounded-md text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50"
            >
              <span className="font-sm mr-2">○</span> {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Menu
