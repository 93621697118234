import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'

import React from 'react'

const GoToBt = ({ el, label = 'Suite' }) => {
  return (
    <a
      className="text-xs pointer-events-auto cursor-pointer flex flex-col justify-center items-center hover:scale-110 transform duration-500"
      href={`#${el}`}
    >
      {label !== 'Suite' ? (
        <>
          <AiOutlineArrowUp className="h-5 w-5 text-black" />
          {label}
        </>
      ) : (
        <>
          {label}
          <AiOutlineArrowDown className="h-5 w-5 text-black" />
        </>
      )}
    </a>
  )
}

export default GoToBt
