import React, { FC } from 'react'

import { AiOutlineCloseCircle } from 'react-icons/ai'

type ButtonProps = {
  label?: string
  type?: 'icon' | 'text'
}

const Button: FC<ButtonProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  label,
  type = 'text',
  ...props
}) => {
  if (type === 'text') {
    return (
      <button
        type='button'
        onClick={props.onClick}
        className='w-[130px] inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
      >
        {label}
      </button>
    )
  } else {
    return (
      <button
        type='button'
        onClick={props.onClick}
        className='absolute right-4 top-4 z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
      >
        <AiOutlineCloseCircle className='h-5 w-5' aria-hidden='true' />
      </button>
    )
  }
}

export default Button
