import React from 'react'
import { browserName } from 'react-device-detect'

const Parchment = ({ customClass }) => {
  return (
    <div
      className={`${
        browserName === 'Mobile Safari' || browserName === 'Safari'
          ? 'parchment-safari'
          : 'parchment'
      }
        ${customClass ? customClass : ''}`}
    ></div>
  )
}

export default Parchment
