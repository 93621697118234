import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

import React from 'react'

export const ButtonShare = ({ repoId }) => {
  return (
    <div className='flex gap-4 justify-center'>
      <LinkedinShareButton
        url={`https://brest44.jeofun.fr`}
        title='Découvrez la bataille de Brest'
      >
        <LinkedinIcon
          style={{ opacity: '1' }}
          size={40}
          color='#0e76a8'
          round={true}
        />
      </LinkedinShareButton>

      <FacebookShareButton
        url={`https://brest44.jeofun.fr`}
        hashtag='Brest'
        title='Découvrez la bataille de Brest'
      >
        <FacebookIcon
          style={{ opacity: '1' }}
          size={40}
          color='#3b5998'
          round={true}
        />
      </FacebookShareButton>

      <TwitterShareButton
        url={`https://brest44.jeofun.fr`}
        title='Découvrez la bataille de Brest'
        hashtags={['brest', 'brestrasee']}
        via='jeofun'
      >
        <TwitterIcon
          style={{ opacity: '1' }}
          size={40}
          color='#00acee'
          round={true}
        />
      </TwitterShareButton>

      <EmailShareButton
        url={`https://brest44.jeofun.fr`}
        subject='Un site bien fait sur la bataille de Brest en 1944 à découvrir !'
        body='À voir et à partager :)'
      >
        <EmailIcon
          style={{ opacity: '1' }}
          size={40}
          color='#b24f4e'
          round={true}
        />
      </EmailShareButton>
    </div>
  )
}
