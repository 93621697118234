import Parchment from '../Parchment'
import React from 'react'

const StepFull = ({ record }) => {
  return (
    <div
      className={`${
        record.className ? record.className : ''
      } xs:2/3 md:w-3/4 m-auto xs:p-4 lg:p-2 pointer-events-auto step-full`}
    >
      <div
        className={`xs:p-4 lg:p-20 relative text-blue-dark step-full w-full h-full lg:flex lg:flex-col lg:justify-center lg:items-center z-10`}
      >
        <Parchment customClass="" />
        <div className="overflow-auto pointer-events-auto max-h-full">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: record.description }}
          />
        </div>
      </div>
    </div>
  )
}

export default StepFull
