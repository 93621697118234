import {
  AiFillCloseCircle,
  AiOutlineBorder,
  AiOutlineCheckSquare,
  AiOutlineCopyrightCircle,
  AiOutlineMore,
  AiOutlineSwitcher,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai'
import { FC, useEffect, useState } from 'react'
import { ToolbarIsOpen, openToolbar } from '../../reducers/toolbarSlice'
import { humanLayer, setLayerOpacity } from '../../tools/helpers'
import { useAppDispatch, useAppSelector } from '../../hooks'

import Menu from '../../components/Menu/Menu'
import Parchment from '../../components/Parchment'

type ToolbarProps = {
  map: any
}

type Layer = {
  opacity: string
  layer: string
  display?: boolean
}

const Toolbar: FC<ToolbarProps> = ({ map }) => {
  const toolbar = useAppSelector((state) => state.toolbar)
  const data = useAppSelector((state) => state.data)
  const [display, setDisplay] = useState('legend')
  const dispatch = useAppDispatch()
  const [layers, setLayers] = useState(null)

  useEffect(() => {
    if (data.current.onChapterEnter.length > 0) {
      const layersOpacity1: Layer[] = data.current.onChapterEnter.filter(
        (layer) => layer.opacity === '1'
      )
      if (layersOpacity1) {
        const initLayers = layersOpacity1.map((element) => {
          return { ...element, display: true }
        })
        setLayers(initLayers)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (layers) {
      layers.forEach((layer: any) => {
        setLayerOpacity(layer, map)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers])

  const renderMenu = () => {
    return (
      <>
        <div
          className='absolute top-6 right-2 p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
          title='Fermer'
          onClick={() => {
            dispatch(
              ToolbarIsOpen({
                isOpen: false,
              })
            )
          }}
        >
          <AiFillCloseCircle size='1.3em' color='#f5f0dd' />
        </div>
        <h3 className='xs:text-xl xs:leading-5 font-bold mb-4 text-red'>
          Menu
        </h3>
        <Menu items={data.data.chapters} />
      </>
    )
  }
  const renderCopyright = () => {
    return (
      <>
        <div
          className='absolute top-6 right-2 p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
          title='Fermer'
          onClick={() => {
            dispatch(
              ToolbarIsOpen({
                isOpen: false,
              })
            )
          }}
        >
          <AiFillCloseCircle size='1.3em' color='#f5f0dd' />
        </div>

        <h3 className='xs:text-xl xs:leading-5 font-bold mb-4 text-red'>
          Légende & Copyright
        </h3>
        {data.current.legende.length > 0 && (
          <div className='mt-2'>
            <div className='mt-2 mb-4 border-t-2 border-blue-dark opacity-20'></div>
            {data.current.legende.map((legende, k) => {
              return (
                <div
                  key={`id-${k}`}
                  className='legende flex items-center text-xs'
                >
                  <img
                    src={legende.img}
                    className='mr-2'
                    alt='Picto de légende'
                  />{' '}
                  <div className='break-words table-cell'>{legende.title}</div>
                </div>
              )
            })}

            <div className='mt-4 mb-2 border-t-2 border-blue-dark opacity-20'></div>
            <p>
              Une erreur ?{' '}
              <a
                className='font-xs italic underline'
                href='mailto:contact@koality.fr'
              >
                Contactez-nous
              </a>
            </p>
          </div>
        )}
      </>
    )
  }
  const renderLayers = () => {
    return (
      <>
        <div
          className='absolute top-6 right-2 p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
          title='Fermer'
          onClick={() => {
            dispatch(
              ToolbarIsOpen({
                isOpen: false,
              })
            )
          }}
        >
          <AiFillCloseCircle size='1.3em' color='#f5f0dd' />
        </div>

        <h3 className='xs:text-xl xs:leading-5 font-bold mb-4 text-red'>
          Carte
        </h3>
        <div>
          {layers !== null &&
            layers.map((currentLayer) => {
              return (
                <div
                  className='flex items-center gap-2'
                  onClick={() => {
                    let lay = layers.map((l) => {
                      if (l.layer === currentLayer.layer)
                        return {
                          ...currentLayer,
                          display: !currentLayer.display,
                          opacity: currentLayer.opacity === '1' ? '0' : '1',
                        }
                      return l
                    })
                    setLayers(lay)
                  }}
                >
                  {currentLayer.display ? (
                    <AiOutlineCheckSquare />
                  ) : (
                    <AiOutlineBorder />
                  )}{' '}
                  {humanLayer[currentLayer.layer]}
                </div>
              )
            })}
        </div>
      </>
    )
  }

  const renderContent = () => {
    switch (display) {
      case 'menu':
        return renderMenu()
      case 'legend':
        return renderCopyright()
      case 'layers':
        return renderLayers()
      default:
        return renderMenu()
    }
  }

  return (
    <div className='w-full min-h-[80%] overflow-hidden absolute z-10 pt-4 pointer-events-none'>
      <div
        className={`absolute right-0 transform duration-500 p-2 shadow-md w-[45px] pointer-events-auto ${
          !toolbar.isOpen ? '-translate-x-4' : 'translate-x-[100px]'
        }`}
      >
        <div className={`bg-paper-brown inset-0 absolute rounded-md`}></div>
        <div className='flex flex-col gap-2'>
          <div
            className='p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
            title='Menu'
            onClick={() => {
              setDisplay('menu')
              dispatch(
                openToolbar({
                  content: data.data.chapters,
                  isOpen: true,
                })
              )
            }}
          >
            <AiOutlineMore size='1.3em' color='#f5f0dd' />
          </div>
          <div className='relative h-[1px] w-full bg-black bg-opacity-20'></div>
          <div
            className='p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
            title='Afficher / masquer les couches de la carte'
            onClick={() => {
              setDisplay('layers')
              dispatch(
                openToolbar({
                  content: null,
                  isOpen: true,
                })
              )
            }}
          >
            <AiOutlineSwitcher size='1.3em' color='#f5f0dd' />
          </div>
          <div
            className='p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
            title='Afficher les légendes'
            onClick={() => {
              setDisplay('legend')
              dispatch(
                openToolbar({
                  content: data.current,
                  isOpen: true,
                })
              )
            }}
          >
            <AiOutlineCopyrightCircle size='1.3em' color='#f5f0dd' />
          </div>
          <div
            className='p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
            title='Zoom sur la carte'
            onClick={() => map.zoomIn(map.getZoom() + 1)}
          >
            <AiOutlineZoomIn size='1.3em' color='#f5f0dd' />
          </div>
          <div
            className='p-1 bg-red hover:bg-opacity-80 transform cursor-pointer rounded-md'
            title='Dézoomer sur la carte'
            onClick={() => map.zoomOut(map.getZoom() - 1)}
          >
            <AiOutlineZoomOut size='1.3em' color='#f5f0dd' />
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 transform duration-500 p-2 shadow-md w-[300px] p-8 text-black pointer-events-auto ${
          toolbar.isOpen ? '-translate-x-4' : 'translate-x-[500px]'
        }`}
      >
        <div className='parchment rounded-lg'></div>
        <Parchment />
        {renderContent()}
      </div>
    </div>
  )
}

export default Toolbar
