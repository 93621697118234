import { drawerIsOpen, openDrawer } from '../../reducers/drawerSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useEffect, useRef, useState } from 'react'

import ArrowToggle from '../ArrowToggle'
import Button from '../Button/Button'
import ContentBase from '../ContentBase'
import GoToBt from '../GoToBt'
import Parchment from '../Parchment'
import { isMobileOnly } from 'react-device-detect'
import useBreakpoint from '../../tools/useBreakpoint'
import { useWindowSize } from 'usehooks-ts'

const StepLeft = ({ record, index }) => {
  const drawer = useAppSelector((state) => state.drawer)
  const dispatch = useAppDispatch()

  const contentRef = useRef<any>()
  const bp = useBreakpoint()
  const [, setContentHeight] = useState(0)

  const size = useWindowSize()

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [size])

  return (
    <div
      ref={contentRef}
      className={`
      xs:transform xs:duration-500 xs:w-full xs:m-auto xs:mt-0
      md:translate-y-0
      lg:m-0 lg:ml-4 lg:w-1/3
      transform duration-500 relative text-black pointer-events-auto
      ${!drawer.cardIsOpen
          ? bp === 'lg'
            ? '-translate-x-[calc(100%-2.6rem)]'
            : '-translate-x-[calc(100%-4rem)]'
          : 'translate-x-0'
        }
      `}
    >
      <ArrowToggle
        onClick={() => {
          dispatch(
            drawerIsOpen({
              isOpen: false,
              cardIsOpen: !drawer.cardIsOpen,
            })
          )
        }}
        showContent={drawer.cardIsOpen}
        bp={bp}
      />
      <Parchment
        customClass={`${isMobileOnly && drawer.cardIsOpen ? 'scale-x-110' : ''
          }`}
      />

      <div className='pointer-events-auto md:p-8 md:pr-2 xs:px-4 xs:py-8 xs:max-h-[95%] max-h-screen overflow-auto flex justify-between'>
        <div className=' shadow-text pr-8 '>
          <ContentBase {...record} ref={contentRef} />
          <div className='mt-4 flex justify-between items-center'>
            <Button
              label='Lire la suite ...'
              onClick={() => {
                dispatch(
                  openDrawer({
                    content: record,
                    isOpen: true,
                    cardIsOpen: false,
                  })
                )
              }}
            />
          </div>
        </div>

        <div className='ml-3 w-[50px] flex flex-col justify-between'>
          <GoToBt el={`chapter-${index - 1}`} label='Retour' />
          <GoToBt el={`chapter-${index + 1}`} />
        </div>
      </div>
    </div>
  )
}

export default StepLeft
