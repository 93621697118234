import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'

import { AiOutlineArrowDown } from 'react-icons/ai'
import Button from '../Button/Button'
import Parchment from '../Parchment'
import { drawerIsOpen } from '../../reducers/drawerSlice'
import findIndex from 'lodash/findIndex'

type DrawerProps = {
  children?: JSX.Element[]
  isOpen: boolean
}

const Drawer: FC<DrawerProps> = ({ children, isOpen }) => {
  const { drawer, data } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()

  return (
    <>
      {drawer.content && (
        <main
          className={
            ' fixed right-0 top-0 z-10 xs:w-11/12 md:w-1/3 h-full transform ease-in-out ' +
            (isOpen
              ? ' transition-opacity opacity-100 duration-500 translate-x-0  '
              : ' transition-all opacity-0 translate-x-full')
          }
        >
          <Parchment customClass='scale-105' />
          <Button
            type='icon'
            onClick={() => {
              dispatch(
                drawerIsOpen({
                  isOpen: false,
                  cardIsOpen: true,
                })
              )
            }}
          />
          {drawer.content.page && (
            <section
              className={'font-serif p-8 pt-16 w-full right-0 absolute h-full'}
            >
              <article className='relative w-full pb-10 flex flex-col space-y-6 overflow-y-scroll h-full'>
                <div
                  className='description pr-4'
                  dangerouslySetInnerHTML={{
                    __html: drawer.content.page,
                  }}
                />
                <a
                  className='mt-4 text-black text-xs pointer-events-auto cursor-pointer flex flex-col justify-center items-center transform duration-500'
                  href={`#chapter-${
                    findIndex(
                      data.data.chapters,
                      (chapter) => chapter.id === drawer.content.id
                    ) + 1
                  }`}
                >
                  Continuer <AiOutlineArrowDown className='h-5 w-5  ' />
                </a>
              </article>
            </section>
          )}
        </main>
      )}
    </>
  )
}

export default Drawer
