import { ChaptersEntity, Data } from '../types/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchData = createAsyncThunk('data/fetchDataStatus', async () => {
  const response = await fetch(
    `https://storymap.jeofun.fr/wp-json/k/v1/story/3`
  )
  return await response.json()
})

interface DataState {
  data: Data | null
  current: ChaptersEntity | null
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  data: null,
  current: null,
  loading: 'idle',
} as DataState

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    currentChapter: (state, action: PayloadAction<any>) => {
      state.current = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.data = action.payload.data
        state.current = action.payload.data.chapters[0]
      })
      .addCase(fetchData.rejected, (state) => {
        state.loading = 'failed'
      })
  },
})
export const { currentChapter } = dataSlice.actions
export const data = (state: RootState) => state.data
export default dataSlice.reducer
