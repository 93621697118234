import { AiOutlineArrowDown } from 'react-icons/ai'
import React from 'react'

export const StepLoading = ({ record }) => {
  return (
    <div
      className={`${
        record.className ? record.className : ''
      } w-full h-screen flex flex-col pointer-events-auto`}
    >
      <img
        alt=''
        className='mix-blend-overlay object-cover h-full w-full absolute'
        src={record.th}
      />
      {/*div className='object-cover h-full w-full absolute bg-black bg-opacity-40'></div>*/}
      <div
        className={`w-full m-auto h-full flex flex-col justify-center items-center relative z-10`}
      >
        <h3 className='xs:text-2xl lg:text-6xl font-bold mb-8'>
          {record.title}
        </h3>
        <p className='xs:text-2xl lg:text-6xl font-serif italic'>
          {record.subtitle}
        </p>
        <div className='mt-16'>
          <div className='overflow-auto flex flex-col items-center'>
            <p className='xs:text-sm xs:leading-base lg:text- xs:w-3/4 lg:w-fullfont-hand mb-6 text-white drop-shadow-lg italic opacity-80 text-center'>
              Les cartes sont interactives, vous pouvez zoomer et vous y
              déplacer.
              <br />
              <a
                className='mt-4 text-xl text-white pointer-events-auto drop-shadow-lg  cursor-pointer flex flex-col justify-center items-center hover:scale-110 transform duration-500'
                href={`#chapter-1`}
              >
                Commencer <AiOutlineArrowDown className='h-5 w-5  ' />
              </a>
            </p>
          </div>
        </div>

        <p className='xs:text-sm lg:text-sm font-serif italic absolute bottom-2 left-1/2 -translate-x-1/2'>
          Réalisation{' '}
          <a href='https://jeofun.fr' target='_blank'>
            Jeofun
          </a>
        </p>
      </div>
    </div>
  )
}

export default StepLoading
