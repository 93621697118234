import { ChaptersEntity } from '../types/types'
import React from 'react'

const ContentBase = React.forwardRef<HTMLInputElement, ChaptersEntity>(
  (record, ref) => {
    return (
      <div ref={ref}>
        <p className='xs:text-base md:text-xl italic mb-0'>{record.subtitle}</p>
        <h3 className='xs:text-xl xs:leading-5 lg:text-2xl font-bold mb-4 text-red'>
          {record.title}
        </h3>
        <div
          className='description'
          dangerouslySetInnerHTML={{ __html: record.description }}
        />
      </div>
    )
  }
)

export default ContentBase
