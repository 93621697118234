import * as smoothscroll from 'smoothscroll-polyfill'

import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { data, fetchData } from './reducers/dataSlice'
import { useAppDispatch, useAppSelector } from './hooks'

import AnimationData from './animbarge.json'
import Brest44UseMap from './Brest44UseMap'
import { useEffect } from 'react'

function App() {
  const alldata = useAppSelector(data)
  const dispatch = useAppDispatch()
  useEffect(() => { smoothscroll.polyfill() }, [])

  useEffect(() => {
    // Todo
    // Mieux gérer le fetch et les souscis de retour
    if (alldata.data === null) {
      if (alldata.loading === 'idle') dispatch(fetchData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alldata])

  return alldata.loading !== 'succeeded' ? (
    <div className='flex flex-col items-center justify-center h-screen w-full bg-white'>
      <Player
        autoplay
        loop
        src={AnimationData}
        style={{ height: '500px', width: '500px' }}
      >
        <Controls visible={false} />
      </Player>
    </div>
  ) : (
    <div>
      <Brest44UseMap {...alldata.data} />
    </div>
  )
}

export default App
