import { AiOutlineZoomIn } from 'react-icons/ai'
import Button from '../Button/Button'
import { ChaptersEntity } from '../../types/types'
import GoToBt from '../GoToBt'
import { MobileOnlyView } from 'react-device-detect'
import { OpenSeaDragonViewer } from '../OpenSeaDragonViewer'
import Parchment from '../Parchment'
import Popup from 'reactjs-popup'
import { openDrawer } from '../../reducers/drawerSlice'
import { useAppDispatch } from '../../hooks'
import { useState } from 'react'

type StepProps = {
  record: ChaptersEntity
  index: number
}

const StepFullWithPano = ({ record, index }: StepProps) => {
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div
        className={`${record.className ? record.className : ''
          } w-full h-full xs:p-2 lg:p-4 relative pointer-events-auto step-full-with-pano`}
      >
        <div className='relative h-full flex justify-between '>
          <Parchment customClass={`${MobileOnlyView ? '' : ''} `} />
          <div className='flex w-full h-full xs:p-4 lg:p-10 lg:pr-2 relative z-10 '>
            <div
              className={`relative flex flex-col items-center shadow-text xs:pr-4 lg:pr-8 h-full w-full lg:pb-0 pointer-events-auto text-black`}
            >
              <p className='xs:text-base md:text-xl italic mb-0'>
                {record.subtitle}
              </p>
              <h3 className='xs:text-xl xs:leading-5 lg:text-2xl font-bold mb-4 text-red'>
                {record.title}
              </h3>

              <div
                className='group h-full w-full relative cursor-pointer'
                onClick={() => setShowModal(true)}
              >
                <img
                  alt=''
                  className='absolute inset-0 w-full h-full object-cover'
                  src={record.levels[2].url}
                />
                <div className='transition absolute inset-0 bg-opacity-40 flex flex-col items-center justify-center bg-black  text-white'>
                  <div className='cursor-pointer m-1 rounded-full flex justify-center items-center xs:w-8 xs:h-8 lg:w-10 lg:h-10 bg-red'>
                    <AiOutlineZoomIn size='1.5em' color='white' />
                  </div>
                  Agrandir et zoomer
                </div>
              </div>
              <div
                className='description mt-4 mb-4'
                dangerouslySetInnerHTML={{ __html: record.description }}
              />

              <Button
                label='Lire la suite ...'
                className='w-36'
                onClick={() => {
                  dispatch(
                    openDrawer({
                      content: record,
                      isOpen: true,
                      cardIsOpen: false,
                    })
                  )
                }}
              />
            </div>
          </div>

          <div className='xs:p-2 xs:py-8 lg:p-8 mr-3 xs:w-[30px] lg:w-[90px] flex flex-col items-center justify-between text-black'>
            <GoToBt el={`chapter-${index - 1}`} label='Retour' />
            <GoToBt el={`chapter-${index + 1}`} />
          </div>
        </div>
      </div>
      <Popup
        className='osd-popup'
        open={showModal}
        modal
        onClose={() => setShowModal(false)}
      >
        <div className='h-full w-full'>
          <OpenSeaDragonViewer
            handleClose={() => {
              setShowModal(false)
            }}
            img={record.levels}
          />
        </div>
      </Popup>
    </>
  )
}

export default StepFullWithPano
