import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

type ArrowToggleProps = {
  showContent: boolean
  bp: any
}

const ArrowToggle = ({
  showContent,
  bp,
  ...props
}: ArrowToggleProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={`xs:right-4 md:right-3 top-2/4 -translate-y-2/4 bg-transparent rounded-none
    transition transform duration-500 absolute w-8 h-8 flex items-center justify-center lg:flex cursor-pointer`}
      onClick={props.onClick}
    >
      {!showContent ? (
        bp === 'lg' ? ( // Contenu affiché en bureau
          <>
            <AiFillEye />
            <span className='text-[9px] font-medium absolute top-6 '>
              Afficher
            </span>
          </>
        ) : (
          // Contenu affiché en mobile
          <>
            <AiFillEye />
            <span className='text-[9px] font-medium absolute top-6 '>
              Afficher
            </span>
          </>
        )
      ) : bp === 'lg' ? ( // Contenu masqué en bureau
        <>
          <AiFillEyeInvisible />
          <span className='text-[9px] font-medium absolute top-6 '>
            Masquer
          </span>
        </>
      ) : (
        // Contenu masqué en mobile
        <>
          <AiFillEyeInvisible />
          <span className='text-[9px] font-medium absolute top-6 '>
            Masquer
          </span>
        </>
      )}
    </div>
  )
}

export default ArrowToggle
