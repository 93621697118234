import React, { useState } from 'react'

import axios from 'axios'

const config = {
  api: `https://jeofun.fr/wp-json/k/v1/sendform`,
  successMessage: 'Parfait ! Votre message a bien été envoyé !',
  errorMessage:
    'Il semble y avoir un soucis, veuillez réssayer plus tard ou n`hésitez pas nous appeler directement !',
  fields: {
    firstName: '',
    lastName: '',
    email: '',
    msg: '',
  },
  fieldsConfig: [
    {
      id: 1,
      label: 'First Name',
      fieldName: 'firstName',
      value: '',
      type: 'text',
      placeholder: 'Votre prénom',
      isRequired: true,
      klassName: 'flex-1',
    },
    {
      id: 2,
      label: 'Last Name',
      fieldName: 'lastName',
      value: '',
      type: 'text',
      placeholder: 'Votre nom',
      isRequired: true,
      klassName: 'flex-1',
    },
    {
      id: 3,
      label: 'Email',
      fieldName: 'email',
      value: '',
      type: 'email',
      placeholder: ' Votre email',
      isRequired: true,
      klassName: 'w-full',
    },
    {
      id: 4,
      label: 'Message',
      fieldName: 'msg',
      value: '',
      type: 'textarea',
      placeholder: 'Votre message',
      isRequired: true,
      klassName: 'w-full',
    },
  ],
}

const Formcontact = () => {
  const { successMessage, errorMessage, fieldsConfig } = config
  const [mailSent, setmailSent] = useState(false)
  const [error, setError] = useState(null || false)
  const [formData, setFormData] = useState({})

  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  const handleFormSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${config.api}`,
      headers: { 'content-type': 'application/json' },
      data: formData,
    })
      .then((result) => {
        if (result.status === 200) {
          setmailSent(result.data)
          setError(false)
        } else {
          setError(true)
        }
      })
      .catch((error) => setError(error.message))
  }
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - namve of the field
   * @return void
   */
  const handleChange = (e, field) => {
    let value = e.target.value
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  return (
    <div className='contact-form'>
      <div className='contact-form__container'>
        <div>
          <form action='#' className='flex flex-row flex-wrap gap-4'>
            {fieldsConfig &&
              fieldsConfig.map((field) => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== 'textarea' ? (
                      <div className={`${field.klassName}`}>
                        {/*<label>{field.label}</label>*/}
                        <input
                          type={field.type}
                          placeholder={field.placeholder}
                          defaultValue={field.value}
                          className='w-full py-3 px-2'
                          onChange={(e) => handleChange(e, field.fieldName)}
                        />
                      </div>
                    ) : (
                      <div className={`${field.klassName}`}>
                        {/*<label>{field.label}</label>*/}
                        <textarea
                          placeholder={field.placeholder}
                          defaultValue={field.value}
                          className='w-full py-3 px-2'
                          onChange={(e) => handleChange(e, field.fieldName)}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
            <input
              className=' w-[110px] inline-flex items-center justify-center cursor-pointer px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
              type='submit'
              onClick={(e) => handleFormSubmit(e)}
              value='Envoyer'
            />
            <div>
              {mailSent && (
                <div className='font-bold text-green-600'>{successMessage}</div>
              )}
              {error && (
                <div className='font-bold text-red-600'>{errorMessage}</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formcontact
