import { ButtonShare } from '../Button'
import Formcontact from '../Formcontact/Formcontact'
import Parchment from '../Parchment'
import React from 'react'

const StepEnd = ({ record }) => {
  return (
    <div
      className={`${
        record.className ? record.className : ''
      } xs:2/3 md:w-3/4 m-auto xs:p-4 lg:p-2 pointer-events-auto step-full`}
    >
      <div
        className={`xs:p-4 lg:p-20 lg:pt-12 relative text-blue-dark step-full w-full lg:flex lg:flex-col lg:justify-center lg:items-center z-10`}
      >
        <Parchment customClass='' />
        <div className='text-center mb-4 pb-6 border-b-2'>
          <h3 className='xs:text-base lg:text-2xl font-bold mb-4'>
            Vous avez aimé ce site ? Partagez-le !
          </h3>
          <ButtonShare />
        </div>
        <div className='description overflow-auto pointer-events-auto md:grid md:grid-cols-2 md:gap-8'>
          <div
            className='md:text-left'
            dangerouslySetInnerHTML={{ __html: record.description }}
          />
          <div>
            <h3 className='xs:text-base lg:text-xl font-normal mt-4'>
              Vous souhaitez en savoir plus sur ce projet ?
            </h3>
            <h3 className='xs:text-base lg:text-2xl font-bold mb-4'>
              Contactez-nous
            </h3>
            <Formcontact />
            <p className='mt-2'>
              Ou appelez-nous au{' '}
              <a href='tel:02 98 20 77 74'>
                <b>02 98 20 77 74 </b>
              </a>
              <br />
              ou par email à
              <a href='mailto:contact@koality.fr'>
                <b> contact@koality.fr</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepEnd
